<template>
  <div>
    <h1>Einstellungen</h1>
    <v-card class="mb-5">
      <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
      <v-card-text>
        <v-text-field
            v-model="user_updates.tax"
            label="Umsatzsteuersatz"
            type="number"
            :error-messages="apiValidationErrors.tax"
            :disabled="loading || saving"
            suffix="%"
        ></v-text-field>
        <v-text-field
            v-model="user_updates.lexoffice_key"
            label="Neuer Lexoffice Api-Schlüssel"
            :error-messages="apiValidationErrors.lexoffice_key"
            :disabled="loading || saving"
            persistent-hint
            :append-icon="user && user.lexoffice_connected ? 'delete' : null"
            @click:append="removeLexofficeConnection"
            hint='Hier können Sie einen neuen Api-Schlüssel generieren: <a target="_blank" href="https://app.lexoffice.de/settings/#/public-api" >https://app.lexoffice.de/settings/#/public-api</a >'
        >
          <template v-slot:message="{ message }">
            <span v-html="message"></span></template
          >
        </v-text-field>
        <v-row class="mt-5">
          <v-col>
            <v-text-field
                v-model="user_updates.target_daily_hours"
                label="Angestrebte Stunden/Tag"
                :error-messages="apiValidationErrors.target_daily_hours"
                :disabled="loading || saving"
                persistent-hint
                type="number"
                hint="Dies wird nur für die Darstellung in der Statistik genutzt."
            >
              <template v-slot:message="{ message }">
                <span v-html="message"></span></template
              >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                v-model="user_updates.target_daily_earnings"
                label="Angestrebte Einnahmen/Tag"
                :error-messages="apiValidationErrors.target_daily_earnings"
                :disabled="loading || saving"
                persistent-hint
                suffix="€"
                type="number"
                hint="Dies wird nur für die Darstellung in der Statistik genutzt."
            >
              <template v-slot:message="{ message }">
                <span v-html="message"></span></template
              >
            </v-text-field>
          </v-col>
          <v-col>
            <v-select
                :disabled="loading || saving"
                v-model="user_updates.excluded_weekdays"
                :items="[
                { value: 1, text: 'Montag' },
                { value: 2, text: 'Dienstag' },
                { value: 3, text: 'Mittwoch' },
                { value: 4, text: 'Donnerstag' },
                { value: 5, text: 'Freitag' },
                { value: 6, text: 'Samstag' },
                { value: 7, text: 'Sonntag' }
              ]"
                label="Ruhetage"
                multiple
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn class="mr-4" @click="save" color="success" :loading="saving">
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mb-5">
      <v-card-title>Tageseinnahmen errechnen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
                v-model="daily_hours_calculator.yearly_earnings"
                label="Gewünschte Jahreseinnahmen"
                type="number"
            />
          </v-col>
          <v-col>
            <v-text-field
                v-model="daily_hours_calculator.holidays"
                label="Feiertage"
                type="number"
            />
          </v-col>
          <v-col>
            <v-text-field
                v-model="daily_hours_calculator.vacation_days"
                label="Urlaubstage"
                type="number"
            />
          </v-col>
          <v-col>
            <v-text-field
                v-model="daily_hours_calculator.sick_days"
                label="Krankheitstage"
                type="number"
            />
          </v-col>
          <v-col>
            <v-text-field
                disabled
                :value="calculatedDailyEarnings"
                label="Errechnete Tageseinnahmen"
                type="number"
                prefix="="
                suffix="€"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Konto löschen</v-card-title>
      <v-card-text
      >Hier können Sie Ihr Zugangskonto unwiderruflich löschen.
      </v-card-text
      >
      <v-card-actions>
        <v-btn color="error" @click="deleteUser()" dark>Konto Löschen</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  mixins: [formMixin],
  data() {
    return {
      saving: false,
      user_updates: {},
      daily_hours_calculator: {
        yearly_earnings: 60000,
        holidays: 10,
        vacation_days: 60,
        sick_days: 10
      }
    };
  },
  computed: {
    loading() {
      return !this.user;
    },
    user() {
      return this.$store.getters["profile/me"];
    },
    calculatedDailyEarnings() {
      const resting_days = 365 / 7 * (this.user_updates.excluded_weekdays?.length ?? 2);
      const working_days = (365 - resting_days - this.daily_hours_calculator.holidays - this.daily_hours_calculator.vacation_days - this.daily_hours_calculator.sick_days);

      return Math.ceil(
          this.daily_hours_calculator.yearly_earnings /
          working_days
      );
    }
  },
  methods: {
    async save() {
      this.setApiValidation([]);
      this.saving = true;
      const user_updates = this.user_updates;
      if (!user_updates.lexoffice_key) {
        delete user_updates.lexoffice_key;
      }
      try {
        await this.$store.dispatch("profile/update", {
          ...user_updates
        });
        await this.$store.dispatch(
            "alerts/success",
            "Einstellungen gespeichert."
        );
        await this.$store.dispatch("profile/me");
        this.saving = false;
      } catch (e) {
        this.saving = false;
        await this.$store.dispatch("alerts/error", "Fehler!");
        if (e.response) {
          this.setApiValidation(e.response.data.errors);
        }
      }
    },
    async removeLexofficeConnection() {
      this.$swal
          .fire({
            title: "Die Verbindung mit Lexoffice aufheben?",
            showCancelButton: true,
            confirmButtonText: `Verbindung aufheben`,
            cancelButtonText: `Abbrechen`
          })
          .then(result => {
            if (result.isConfirmed) {
              this.setApiValidation([]);
              this.saving = true;
              try {
                this.$store
                    .dispatch("profile/update", {
                      type: "users",
                      id: this.user.id,
                      lexoffice_key: null
                    })
                    .then(() => {
                      this.$store.dispatch(
                          "alerts/success",
                          "Verbindung aufgehoben."
                      );
                      this.$store.dispatch("profile/me");
                    });
                this.saving = false;
              } catch (e) {
                this.saving = false;
                this.$store.dispatch("alerts/error", "Fehler!");
                if (e.response) {
                  this.setApiValidation(e.response.data.errors);
                }
              }
            }
          });
    },
    async deleteUser() {
      this.$swal
          .fire({
            title: "Nutzerkonto unwiderruflich löschen?",
            showCancelButton: true,
            confirmButtonText: `Konto löschen`,
            cancelButtonText: `Abbrechen`
          })
          .then(result => {
            if (result.isConfirmed) {
              try {
                this.$store.dispatch("profile/destroy").then(() => {
                  this.$store.dispatch(
                      "alerts/success",
                      "Ihr Nutzerkonto wurde erfolgreich gelöscht."
                  );
                  this.$router.push({path: "/"});
                });
              } catch (e) {
                this.$store.dispatch("alerts/error", "Fehler!");
                if (e.response) {
                  this.setApiValidation(e.response.data.errors);
                }
              }
            }
          });
    }
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(user) {
        if (user) {
          this.user_updates = user;
        }
      }
    }
  }
};
</script>
